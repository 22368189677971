body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

table.pricing-table {
  border-collapse: separate;
  border-spacing: 0;
}
table.pricing-table th {
  white-space: normal;
}
table.pricing-table td, table.pricing-table th {
  margin: 0;
  padding: 0.55em;
}

div.scrolling-table {
  width: 100%;
  overflow-x: scroll;
  overflow-y: visible;
  padding: 0;
}
table.pricing-table{
  table-layout: auto !important;
}
table.pricing-table th{
  vertical-align: middle;
}
table.pricing-table th h5{
  line-height: 1em !important;
  font-size: 16px;
}
table.pricing-table th:not(.headcol) {
  padding: .33em !important;
}

table.pricing-table .img-fluid {
  min-width: 88px;
}

table.pricing-table .headcol {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  width: auto;
  padding: 5px 5px 5px 0;
  border-top-width: 1px;
  white-space: normal;
  word-wrap: break-word;
  /*only relevant for first row*/
  margin-top: -1px;
  background-color: white;
  z-index: 1000;
  /*compensate for top border*/
}


table.pricing-table .headcol-2 {
  position: -webkit-sticky;
  position: sticky;
  left: 72px;
  width: auto;
  z-index: 1000;
  background-color: white;
  /*compensate for top border*/
}


/*# sourceMappingURL=scrolling_grid.css.map */

table.pricing-table .space-column{
  min-width: 25%;
}
.strike-text{
  text-decoration: line-through;
}
.lh-small{
  line-height: 1.2em;
}
.feature-list{
  font-size: 15px;
  padding-left: 18px;
}


.StripeCheckout{
  white-space: normal;
  background-color: rgb(40, 160, 229) !important;
  background-image: none !important;
  border-radius: 4px !important;
  display: block;
  width: 100%;
}
.StripeCheckout span{
  background: none !important;
  box-shadow: none !important;
  text-shadow: none !important;
  text-align: center;
  height: auto !important;
  line-height: 1.5em !important;
  padding: 10px !important;
}

.StripeCheckout span:before{
  position: relative;
  display: block;
  text-decoration: line-through;
  white-space: nowrap;
}
span.Pump-free button.btn{
  padding: 10px;
  white-space: nowrap !important;  
}
span.Pump-free button.btn strong{
  pointer-events: none;
}
span.Pump-free button.btn strong b{
  font-size: larger;
  font-weight: bolder;
  line-height: 1em;
}
.confirm-section{
  position: relative;
}

#productsWrapper:not(.more) + .indicator, #productsWrapper:not(.more) + .indicator + .indicator {
  display: none;
}
.confirm-section .indicator{
  position: absolute;
  width: 30px;
  top: 0;
  bottom: 0px;
  height: 100%;
  display: flex;
  align-items: center;
  padding-top: 93px;

}
#productsWrapper.more + .indicator#indicator-click-action-right{
  right: -15px;
  background: -moz-linear-gradient(left,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
}
#productsWrapper.more + .indicator + .indicator#indicator-click-action-left{
  visibility: hidden;
  left: 65px;
  z-index: 10000;

  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&1+0,0+100 */
  background: -moz-linear-gradient(left,  rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 */
}

#productsWrapper.more + .indicator svg,
#productsWrapper.more + .indicator + .indicator svg{
  color: #cccccc;
    -webkit-animation: slide-right 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) 8 alternate both;
    animation: slide-right 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) 8 alternate both;
}

 @-webkit-keyframes slide-right {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-15px);
            transform: translateX(-15px);
  }
}
@keyframes slide-right {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-15px);
            transform: translateX(-15px);
  }
}
