.App {
  text-align: center;
}
.visible{
  visibility: visible !important;
}
.invisible{
  visibility: hidden;
}
h1{
  font-size: 1.5rem;
  margin-bottom: 1rem;
}
h1 > small{
  display: block;
}
h1 + p{
  font-weight: 400;
  font-size: 12px;
  
}

.brands-row.brand-style3{
  display: none;
}
.brands-row.brand-style3 + .step1.wrapper-style3{
  margin-top: 180px !important;
}

.brands-row{
  height: 265px;
  position: relative;
}
.brands-row figure{
  margin: 0;
  position: absolute;
  bottom: 0px;
}
.brands-row figure .img-fluid{
  margin-bottom: 0;
}
.brands-row .figure-caption{
  color: white;
  font-weight: bold;
  position: relative;
  top: 5px;
  font-size: 18px;
  text-align: center;
  text-shadow: 0px 1px 2px #AAAAAA;
}
@media (max-width: 480px) {
  .brands-row{
    height: 180px;
  }
  .brands-row .figure-caption{
    top: 5px;
    font-size: 12px;
  }
  .brands-row.brand-style3 + .step1.wrapper-style3{
    margin-top: 130px !important;
  }

}
.progressBar-step{
  padding: 5px;
  float: right;
}

.form-label{
  font-size: 14px;
  line-height: 10px;
}
.form-text{
  font-size: 10px;
  line-height: 10px;
  text-align: right;
  position: relative;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container{
  text-align: left;
}

.progress{
  height: 0.35rem;
  margin-bottom: 25px;
}
.hidden{
  display: none;
}

.compare-table td, .compare-table th{
  text-align: center;
  font-size: 12px;
  vertical-align: middle;
}
.compare-table button.btn{
  white-space: normal !important;
  font-size: 14px;
}
.compare-table button.btn > span{
  display: inline-block;
}
.compare-table tr td:nth-child(1){
  line-height: 1em;
  text-align: left;
  font-weight: bold;
}

.row.selected{
  border: 1px solid #f1f1f1;
}
.row .figureContainer{
  padding: 15px 33px;
}
.row.selected .figureContainer{
  background-color: #f1f1f1;
  box-shadow: 0px 10px 22px -20px #000000;
}

.compare-table.Hygeia tr td:nth-child(2){
  background-color: #f1f1f1;
}
.compare-table.Hygeia tr:last-of-type td:nth-child(2){
  box-shadow: 0px 10px 22px -20px #000000;
}
.compare-table.Hygeia tr:first-of-type td:nth-child(2){
  box-shadow: 0px 10px 22px -20px #000000 inset;
}

.compare-table.Hygeia tr td:nth-child(2) button.btn:before{
    content: "\f00c";
    display: inline;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 1.2em;
    padding-right: 3px;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: auto;
}

.compare-table.Ameda tr td:nth-child(3){
  background-color: #f1f1f1;
}
.compare-table.Ameda tr:last-of-type td:nth-child(3){
  box-shadow: 0px 10px 22px -20px #000000;
}
.compare-table.Ameda tr:first-of-type td:nth-child(3){
  box-shadow: 0px 10px 22px -20px #000000 inset;
}

.compare-table.Ameda tr td:nth-child(3) button.btn:before{
  content: "\f00c";
  display: inline;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 1.2em;
  padding-right: 3px;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: auto;
}


.compare-table.Spectra tr td:nth-child(4){
  background-color: #f1f1f1;
}
.compare-table.Spectra tr:last-of-type td:nth-child(4){
  box-shadow: 0px 10px 22px -20px #000000;
}
.compare-table.Spectra tr:first-of-type td:nth-child(4){
  box-shadow: 0px 10px 22px -20px #000000 inset;
}
.compare-table.Medela tr td:nth-child(5){
  background-color: #f1f1f1;
}
.compare-table.Medela tr:last-of-type td:nth-child(5){
  box-shadow: 0px 10px 22px -20px #000000;
}
.compare-table.Medela tr:first-of-type td:nth-child(5){
  box-shadow: 0px 10px 22px -20px #000000 inset;
}
.green{
  color: #37d180;
}
.grey{
  color: grey;
}
.face-emoticon{
  color: #F6B547;
}
.rate-value-text{
  display: block;
  font-size: 16px;
  font-weight: 400;
  color: #D3904F;
}


.FormCheck-radio{
  padding: 25px 20px;
  border-radius: 4px;
  border: 1px solid #f1f1f1;
  margin-bottom: 33px;
}
.FormCheck-radio input{
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: auto;
  right: 0;
  opacity: 0;
}

.FormCheck-radio input + label{
  font-weight: 800;
}
.FormCheck-radio input + label:before{
  content: " ";
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  right: -6px;
  font-size: 2em;
  top: -5px;
  background-color: white;
  border-radius: 50%;
  box-shadow: 2px 2px 10px -6px #000000;
  border: 1px solid #f0f0f0;
}
.FormCheck-radio input:checked + label:before{
  content: "\f00c";
}

.FormCheck-radio.selected{
  background-color: #f1f1f1;
  box-shadow: 0px 10px 22px -20px #000000;
}
.invalid{
  border-color: red;
}
.errorMessage{
  visibility: hidden;
  font-size: 10px;
  color: white;
  font-weight: bold;
  display: block;
  padding: 2px;
  text-align: left;
  float: left;
  border-radius: 5px;
  background-color: red;
  position: absolute;
  top: 0px;
}
.obg-suggest span{
  padding-right: 10px;
  pointer-events: none;
}
.agreement{
  max-height: 150px;
  overflow: scroll;
  font-size: 12px;
  padding-bottom: 5px;
}

.agreement + form > .custom-switch{
  font-size: 12px;
  font-weight: bold;
  padding-bottom: 15px;
}
.form-control{
  position: relative;
}

input[type="date"].form-control::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 1;
}
.submitting{
  background-color: rgba(255,255,255,0.85);
  z-index: 1000;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.submitting > div{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-items: center;
}
.submitting > div::before{
  content: "loading";
  text-align: center;
  position: absolute;
  font-weight: bold;
  color: #37d180;
  font-size: 18px;
}
.autocomplete-dropdown-container:empty {
  border: none;
}
.autocomplete-dropdown-container {
  border: 1px solid #999999;
}

.autocomplete-dropdown-container .suggestion-item > span, .autocomplete-dropdown-container .suggestion-item--active > span{
  padding: 10px 10px;
}
.autocomplete-dropdown-container .suggestion-item > span:hover{
  cursor: pointer;
}
@media (max-width: 480px) {
  h1{
    font-size: 1.2rem !important;
  }
  .wrapper-style1:not(.step1), .wrapper-style2:not(.step1), .wrapper-style3:not(.step1){
    margin-top: 130px !important;
  }
  .wrapper-style1::before, .wrapper-style2::before, .wrapper-style3::before {
    width: 110px !important;
    height: 110px !important;
  }
}
.pump-container{
  float: right;
  clear: none;
}
.pump-container figure.figure{
  margin: 0;
  padding: 0;
}
.pump-container img{
  max-height: 130px;
  margin: 0;
  padding: 0;
  margin-top: -40px;
}

.Select-menu-outer {
  top: auto;
  bottom: 100%;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom-right-radius: unset;
  border-bottom-left-radius: unset;
}
.Select-option:first-child {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.Select-option:last-child {
  border-bottom-right-radius: unset;
  border-bottom-left-radius: unset;
}
.Select.is-open > .Select-control {
  border-top-right-radius: unset;
  border-top-left-radius: unset;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

/*THEMES GO HERE */


.style1 {
  background-color: #a47fd6;
}

.style1 .wrapper-style1 {
  margin-top: 180px;
  -webkit-box-shadow: 0 20px 24px -15px #7D48C4;
          box-shadow: 0 20px 24px -15px #7D48C4;
  border: 1px solid #a47fd6;
  padding: 0 15px;
  border-radius: 5px;
  background-color: white;
}

.style1 .wrapper-style1::before {
  content: "";
  background-image: url("https://lh3.googleusercontent.com/KWrGAL7DQSkdUG2Ltz16OivMhTguoSkzkank55gb6qL1IRsMTHZPkBeUDJgNpMHtbip8fpVCsmLzCRTk56V_RaU=s0");
  background-repeat: no-repeat;
  position: absolute;
  width: 150px;
  height: 150px;
  top: 15px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background-size: contain;
}

.style1 .submitting {
  background-color: rgba(164, 127, 214, 0.5);
}

.style1 .submitting > div svg {
  color: #7D48C4;
}

.style1 .submitting > div::before {
  color: #7D48C4;
}

.style1 .progress {
  margin-left: -15px;
  margin-right: -15px;
  background-color: #b191dc;
}
.style1 .progressBar-step{
  background-color: #b191dc;
}

.style1 .progress .progress-bar {
  background-color: #7D48C4;
}

.style1 h1 {
  color: #7D48C4;
}
.style1 h1 small{
    color: #4b2b76;
}
.style1 .btn.btn-default {
  color: #7D48C4;
}

.style1 .btn.btn-outline-primary {
  color: #7D48C4;
  border-color: #8a5aca;
}

.style1 .btn.btn-outline-primary:hover,
.style1 .btn.btn-outline-primary.active {
  background-color: #976dd0;
  border-color: #8a5aca;
  color: whitesmoke;
}

.style1 .btn.btn-primary {
  background-color: #7D48C4;
  border-color: #8a5aca;
}

.style1 .btn.btn-primary:hover {
  background-color: #976dd0;
  border-color: #8a5aca;
}

.style1 .btn-primary.disabled, .style1 .btn-primary:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.style1 .form-control:focus {
  border-color: #976dd0;
  -webkit-box-shadow: 0 0 0 0.2rem #bea4e2;
          box-shadow: 0 0 0 0.2rem #bea4e2;
}

.style1 .form-control {
  border-color: #bea4e2;
  color: #4b2b76;
}

.style1 .form-control::-webkit-input-placeholder {
  color: #b191dc;
}

.style1 .form-control:-ms-input-placeholder {
  color: #b191dc;
}

.style1 .form-control::-ms-input-placeholder {
  color: #b191dc;
}

.style1 .form-control::placeholder {
  color: #b191dc;
}

.style1 .custom-control-input:checked ~ .custom-control-label::before {
  color: #583289;
  border-color: #583289;
  background-color: #8a5aca;
}

.style1 .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: white;
  border-color: #bea4e2;
}

.style1 .custom-control-label {
  color: #4b2b76;
}

.style1 .form-check-input.is-valid ~ .form-check-label, .style1 .was-validated .form-check-input:valid ~ .form-check-label {
  color: #643a9d;
}

.style1 .valid-feedback {
  color: #8a5aca;
}

.style1 .border-top {
  border-color: #bea4e2 !important;
}

.style1 .FormCheck-radio {
  border: 1px solid #bea4e2;
}

.style1 .FormCheck-radio.selected {
  background-color: #b191dc;
  -webkit-box-shadow: 0px 10px 22px -20px #976dd0;
          box-shadow: 0px 10px 22px -20px #976dd0;
}
.style1 .FormCheck-radio.selected input + label , .style1 .FormCheck-radio.selected div , .style1 .FormCheck-radio.selected ul li{
  color: whitesmoke !important;
}
.style1 .selected input + label:before{
  color: #643a9d;
}
.style1 .react-select__control {
  border-color: #bea4e2;
}

.style1 .react-select__control--is-focused {
  border-color: #976dd0;
  -webkit-box-shadow: 0 0 0 0.2rem #bea4e2;
          box-shadow: 0 0 0 0.2rem #bea4e2;
}

.style1 .react-select__control:hover {
  border-color: #8a5aca;
}

.style1 .react-select__control .react-select__single-value {
  color: #4b2b76;
}

.style1 .react-select__control .react-select__placeholder {
  color: #b191dc;
}

.style1 .react-select__control .react-select__dropdown-indicator {
  color: #7D48C4;
}

.style1 .react-select__control .react-select__indicator-separator {
  background-color: #7D48C4;
}

.style1 .react-select__menu .react-select__menu-list .react-select__option--is-focused {
  background-color: #976dd0;
  color: white;
}

.style1 .react-select__menu .react-select__menu-list .react-select__option--is-selected {
  background-color: #7D48C4;
  color: white;
}

.style2 {
  background-color: #f6d8ac;
}

.style2 .wrapper-style2 {
  margin-top: 180px;
  -webkit-box-shadow: 0 20px 24px -15px #EDB059;
          box-shadow: 0 20px 24px -15px #EDB059;
  border: 1px solid #f1c07a;
  padding: 0 15px;
  border-radius: 5px;
  background-color: white;
}

.style2 .wrapper-style2::before {
  content: "";
  background-image: url("https://moms.abreastpumpandmore.com/wp-content/uploads/2016/03/hg-logo.png");
  background-repeat: no-repeat;
  position: absolute;
  width: 150px;
  height: 150px;
  top: 30px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background-size: contain;
}

.style2 .submitting {
  background-color: rgba(246, 216, 172, 0.5);
}

.style2 .submitting > div svg {
  color: #EDB059;
}

.style2 .submitting > div::before {
  color: #EDB059;
}

.style2 .progress {
  margin-left: -15px;
  margin-right: -15px;
  background-color: #f2c88b;
}
.style2 .progressBar-step{
  background-color: #f2c88b;
}
.style2 .progress .progress-bar {
  background-color: #EDB059;
}

.style2 h1 {
  color: #EDB059;
}
.style2 h1 small{
  color: #485057;
}
.style2 .btn.btn-default {
  color: #EDB059;
}

.style2 .btn.btn-outline-primary {
  color: #d59e50;
  border-color: #efb86a;
}

.style2 .btn.btn-outline-primary:hover,
.style2 .btn.btn-outline-primary.active {
  background-color: #f1c07a;
  border-color: #efb86a;
  color: whitesmoke;
}

.style2 .btn.btn-primary {
  background-color: #d59e50;
  border-color: #efb86a;
}

.style2 .btn.btn-primary:hover {
  background-color: #f1c07a;
  border-color: #efb86a;
}

.style2 .btn-primary.disabled, .style2 .btn-primary:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.style2 .form-control:focus {
  border-color: #f1c07a;
  -webkit-box-shadow: 0 0 0 0.2rem #f6d8ac;
          box-shadow: 0 0 0 0.2rem #f6d8ac;
}

.style2 .form-control {
  border-color: #f1c07a;
  color: #485057;
}

.style2 .form-control::-webkit-input-placeholder {
  color: #f1c07a;
}

.style2 .form-control:-ms-input-placeholder {
  color: #f1c07a;
}

.style2 .form-control::-ms-input-placeholder {
  color: #f1c07a;
}

.style2 .form-control::placeholder {
  color: #f1c07a;
}

.style2 .custom-control-input:checked ~ .custom-control-label::before {
  color: #a67b3e;
  border-color: #a67b3e;
  background-color: #efb86a;
}

.style2 .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: white;
  border-color: #f6d8ac;
}

.style2 .custom-control-label {
  color: #8e6a35;
}

.style2 .form-label {
  color: #94989C;
}

.style2 .form-text {
  color: #BFC1C4 !important;
}

.style2 .form-check-input.is-valid ~ .form-check-label, .style2 .was-validated .form-check-input:valid ~ .form-check-label {
  color: #8e6a35;
  text-shadow: 1px 1px 2px #BFC1C4;
}

.style2 .valid-feedback {
  color: #be8d47;
  text-shadow: 1px 1px 1px #E5E6E7;
}

.style2 .border-top {
  border-color: #f6d8ac !important;
}

.style2 .FormCheck-radio {
  border: 1px solid #f6d8ac;
}

.style2 .FormCheck-radio.selected {
  background-color: #f2c88b;
  -webkit-box-shadow: 0px 10px 22px -20px #f1c07a;
          box-shadow: 0px 10px 22px -20px #f1c07a;
}

.style2 .react-select__control {
  border-color: #f1c07a;
}

.style2 .react-select__control--is-focused {
  border-color: #f1c07a;
  -webkit-box-shadow: 0 0 0 0.2rem #f6d8ac;
          box-shadow: 0 0 0 0.2rem #f6d8ac;
}

.style2 .react-select__control:hover {
  border-color: #efb86a;
}

.style2 .react-select__control .react-select__single-value {
  color: #485057;
}

.style2 .react-select__control .react-select__placeholder {
  color: #f1c07a;
}

.style2 .react-select__control .react-select__dropdown-indicator {
  color: #EDB059;
}

.style2 .react-select__control .react-select__indicator-separator {
  background-color: #EDB059;
}

.style2 .react-select__menu .react-select__menu-list .react-select__option--is-focused {
  background-color: #f1c07a;
  color: white;
}

.style2 .react-select__menu .react-select__menu-list .react-select__option--is-selected {
  background-color: #EDB059;
  color: white;
}

.style3 {
  background-color: #fed8e0;
}

.style3 .wrapper-style3 {
  margin-top: 180px;
  -webkit-box-shadow: 0 20px 24px -15px #b18b93;
          box-shadow: 0 20px 24px -15px #b18b93;
  border: 1px solid #fed8e0;
  padding: 0 15px;
  border-radius: 5px;
  background-color: white;
}

.style3 .wrapper-style3::before {
  content: "";
  background-image: url("https://moms.abreastpumpandmore.com/images/mgm-logo-150.png");
  background-repeat: no-repeat;
  position: absolute;
  width: 150px;
  height: 150px;
  top: 15px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background-size: contain;
}

.style3 .submitting {
  background-color: rgba(254, 216, 224, 0.5);
}

.style3 .submitting > div svg {
  color: #FDC7D2;
}

.style3 .submitting > div::before {
  color: #FDC7D2;
}

.style3 .progress {
  margin-left: -15px;
  margin-right: -15px;
  background-color: #FDC7D2;
}

.style3 .progress .progress-bar {
  background-color: #4698ae;
}
.style3 .progressBar-step{
  background-color: #4698ae;
}
.style3 h1 {
  color: #357283;
  text-shadow: 1px 1px 0px #FDC7D2;
}
.style3 h1 small{
  color: #357283;
}

.style3 .btn.btn-default {
  color: #58BEDA;
}

.style3 .btn.btn-outline-primary {
  color: #4fabc4;
  border-color: #fdcdd7;
}

.style3 .btn.btn-outline-primary:hover,
.style3 .btn.btn-outline-primary.active {
  background-color: #79cbe1;
  border-color: #69c5de;
  color: whitesmoke;
}

.style3 .btn.btn-primary {
  background-color: #4fabc4;
  border-color: #fdcdd7;
}

.style3 .btn.btn-primary:hover {
  background-color: #79cbe1;
  border-color: #69c5de;
}

.style3 .btn-primary.disabled, .style3 .btn-primary:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.style3 .form-control:focus {
  border-color: #b18b93;
  -webkit-box-shadow: 0 0 0 0.2rem #fdd2db;
          box-shadow: 0 0 0 0.2rem #fdd2db;
}

.style3 .form-control {
  border-color: #fdcdd7;
  color: #357283;
}

.style3 .form-control::-webkit-input-placeholder {
  color: #98777e;
}

.style3 .form-control:-ms-input-placeholder {
  color: #98777e;
}

.style3 .form-control::-ms-input-placeholder {
  color: #98777e;
}

.style3 .form-control::placeholder {
  color: #98777e;
}

.style3 .custom-control-input:checked ~ .custom-control-label::before {
  color: #b18b93;
  border-color: #58BEDA;
  background-color: #69c5de;
}

.style3 .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: white;
  border-color: #acdfed;
}

.style3 .custom-control-label {
  color: #357283;
}

.style3 .form-label {
  color: #69c5de;
}

.style3 .form-text {
  color: #e4b3bd !important;
}

.style3 .form-check-input.is-valid ~ .form-check-label, .style3 .was-validated .form-check-input:valid ~ .form-check-label {
  color: #3e8599;
}

.style3 .valid-feedback {
  color: #4fabc4;
}

.style3 .border-top {
  border-color: #fed8e0 !important;
}

.style3 .FormCheck-radio {
  border: 1px solid #fee3e9;
  color: #58BEDA;
}

.style3 .FormCheck-radio.selected {
  background-color: #fed8e0;
  -webkit-box-shadow: 0px 10px 22px -20px #b18b93;
          box-shadow: 0px 10px 22px -20px #b18b93;
  color: #58BEDA;
}

.style3 .react-select__control {
  border-color: #fed8e0;
}

.style3 .react-select__control--is-focused {
  border-color: #fdd2db;
  -webkit-box-shadow: 0 0 0 0.2rem #fee3e9;
          box-shadow: 0 0 0 0.2rem #fee3e9;
}

.style3 .react-select__control:hover {
  border-color: #fdcdd7;
}

.style3 .react-select__control .react-select__single-value {
  color: #357283;
}

.style3 .react-select__control .react-select__placeholder {
  color: #98777e;
}

.style3 .react-select__control .react-select__dropdown-indicator {
  color: #FDC7D2;
}

.style3 .react-select__control .react-select__indicator-separator {
  background-color: #FDC7D2;
}

.style3 .react-select__menu .react-select__menu-list .react-select__option--is-focused {
  background-color: #fdd2db;
}

.style3 .react-select__menu .react-select__menu-list .react-select__option--is-selected {
  background-color: #FDC7D2;
}
/*# sourceMappingURL=style.css.map */